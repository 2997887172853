<template>
  <div class="knowledge-base-add-component book">
    <div class="knowledge-base-add-component__header">
      <div
        @click="$store.commit('changeCreateMode', false)"
        class="knowledge-base-add-component__to-back"
      >
        <i class="icon-left"></i>
        <span>Назад</span>
      </div>
    </div>
    <Widget type="loader" v-if="loading" />
    <div class="knowledge-base-add-component__bg" v-show="!loading">
      <div
        class="knowledge-base-add-component__settings knowledge-base-add-component__settings--book"
      >
        <div class="knowledge-base-add-component__settings__title">Добавление книги</div>
        <Widget
          class="select-bg"
          type="input-title"
          placeholder="Введите название"
          maxlength="64"
          unique="nameBook"
        />
        <Widget
          class="select-bg"
          type="input-main"
          placeholder="Введите автора(ов)"
          label="Автор"
          maxlength="64"
          unique="authorBook"
        />
        <Widget
          class="select-bg"
          type="text"
          maxlength="1000"
          label="Описание"
          placeholder="Добавить описание"
          unique="descriptionBook"
        />
        <Widget
          class="select-bg"
          type="select-new"
          :entity="'Category'"
          :actionEntity="'actionGetItems'"
          :itemName="'name'"
          :keyValue="'Category'"
          :maxCount="5"
          :label="'Категория'"
          :placeholder="'Добавить категорию'"
          :multiple="true"
          :createMode="true"
        />
        <Widget
          class="select-bg"
          type="select-new"
          :entity="'Tag'"
          :actionEntity="'actionGetItems'"
          :itemName="'name'"
          :keyValue="'Tag'"
          :maxCount="10"
          :label="'Теги'"
          :placeholder="'Добавить теги'"
          :multiple="true"
          :createMode="true"
        />
        <div class="knowledge-base-add-component__settings__cover">
          <FileUploaderCover
            v-bind="{
              multiple: false,
              drop: true,
              dropDirectory: true,
              fileDrop: true
            }"
            @changeCover="changeCover"
          />
        </div>
        <div class="knowledge-base-add-component__settings__buttons">
          <ModalButton
            :type="'cancel'"
            :title="'Отменить'"
            @handleButton="$root.$emit(`button:click_cancelCreateBook`)"
          />
          <ModalButton :title="'Добавить'" @handleButton="$root.$emit(`button:click_createBook`)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { domain } from "@/globalVariables";
const Widget = () => import("@/components/Widgets/Widget");
import ModalButton from "@/components/Widgets/Button/ModalButton";
import httpClient from "@/api/client/httpClient";
const FileUploaderCover = () => import("@/views/KnowledgeBase/FileUploaderCover/FileUploaderCover");

export default {
  name: "BookAddComponent",
  mixins: [],
  props: [],
  components: { FileUploaderCover, Widget, ModalButton },
  data() {
    return {
      nameBook: "",
      authorBook: "",
      descriptionBook: "",
      categoryBook: [],
      tagBook: [],
      cover: "",
      category: "",
      fileUploader: [],
      timerId: 0
    };
  },
  beforeDestroy() {
    this.$root.$off(`button:click_cancelCreateBook`);
    this.$root.$off(`button:click_createBook`);
  },
  mounted() {
    this.$root.$on(`text:change_nameBook`, (val) => {
      this.nameBook = val;
    });
    this.$root.$on(`text:change_authorBook`, (val) => {
      this.authorBook = val;
    });
    this.$root.$on(`textarea:change_descriptionBook`, (val) => {
      this.descriptionBook = val;
    });
    this.$root.$on(`changeSelectValue:Category`, (val) => {
      this.categoryBook = val;
    });
    this.$root.$on(`changeSelectValue:Tag`, (val) => {
      this.tagBook = val;
    });
    this.$root.$on(`button:click_cancelCreateBook`, () => {
      this.$store.commit("changeCreateMode", false);
    });
    this.$root.$on(`button:click_createBook`, () => {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.createChunksPoolFiles();
      }, 300);
    });
  },
  methods: {
    createBook() {
      httpClient({
        url: `/Book/actionCreate`,
        method: "POST",
        data: [
          {
            name: this.nameBook,
            authors: this.authorBook,
            description: this.descriptionBook,
            categories: this.categoryBook.map((item) => item.id),
            tags: this.tagBook.map((item) => item.id),
            cover: this.cover
          }
        ]
      })
        .then((resp) => {
          this.$store.commit("changeCreateMode", false);
          this.$emit("updateBooksList");
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: `Книга успешно создана!`
          });
        })
        .catch((error) => this.errorAlert(error));
    },
    changeCover(cover) {
      this.fileUploader = cover;
    },
    checkValidationData() {
      if (!this.nameBook.trim()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Название книги должно быть введено!`
        });
        return false;
      }
      if (this.nameBook.length > 64) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Название книги не может быть больше 64-х символов!`
        });
        return false;
      }
      if (!this.authorBook.trim()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Автор книги должен быть обязательно!`
        });
        return false;
      }
      if (this.authorBook.length > 64) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Имя Автора(ов) не может быть больше 64-х символов!`
        });
        return false;
      }
      if (!this.descriptionBook.trim()) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Описание книги должно быть введено!`
        });
        return false;
      }
      if (this.descriptionBook.length > 1000) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Описание книги не может быть больше 1000-х символов!`
        });
        return false;
      }
      if (!this.tagBook.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Должен быть выбран минимум 1 Тег!`
        });
        return false;
      }
      if (!this.fileUploader.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: `Обложка книги должна быть обязательно!`
        });
        return false;
      }
      return true;
    },
    async createChunksPoolFiles() {
      if (!this.checkValidationData()) return null;
      if (!this.fileUploader.length) {
        this.createBook();
        return null;
      }
      this.category = "covers";
      this.createPull(this.fileUploader);
    },
    filesUploaded() {
      this.createBook();
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    createPull(arr) {
      if (!arr.length) {
        this.filesUploaded();
        return null;
      }
      httpClient({
        url: `${domain}/monolit/File/createChunkPool`,
        method: "POST",
        data: [
          {
            file_name: arr[0].name
          }
        ]
      })
        .then((response) => {
          this.pullName = response.data.data.chunk_pool_name;
          this.createChunks(arr);
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        });
    },
    async createChunks(arr) {
      await this.getBase64(arr[0].file).then((resp) => {
        let base64 = resp.replace(resp.substring(0, resp.search(",") + 1), "");
        if (arr[0].size < 50000000) {
          this.chunksArr = base64.match(/.{1,500000}/g);
        } else {
          this.chunksArr = base64.match(/.{1,5000000}/g);
        }
        // TODO: Если файлы большие
        // this.chunksArr = base64.match(/.{1,5000000}/g)
      });
      let countError = 0;
      for (const [i, item] of this.chunksArr.entries()) {
        if (countError) {
          this.loading = false;
          this.$root.$emit("showNotification", {
            type: "error",
            timeout: 5000,
            label: `Возникли какие-то проблемы с загрузкой файлов, попробуйте загрузить еще раз!`
          });
          return null;
        }
        await httpClient({
          url: `${domain}/monolit/File/uploadPoolChunk`,
          method: "POST",
          data: [
            {
              pool_name: this.pullName,
              base_64_chunk: item,
              category: this.category
            }
          ]
        })
          .then(() => {
            if (i === this.chunksArr.length - 1) {
              this.getFileFromPool(arr);
            }
          })
          .catch((error) => {
            countError++;
            this.errorAlert(error);
          });
      }
    },
    getFileFromPool(arr) {
      httpClient({
        url: `${domain}/monolit/File/collectFileFromPool`,
        method: "POST",
        data: [this.pullName, this.category]
      })
        .then((response) => {
          this.cover = response.data.data.path;
          arr.splice(0, 1);
          this.createPull(arr);
        })
        .catch((error) => {
          this.errorAlert(error);
        });
    }
  },
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.knowledge-base-add-component {
  border-radius: 24px;
  border: none;

  &--book {
    display: flex;
    justify-content: center;
  }

  &__bg {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .label {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: $color-text-secondary;
  }

  &__header {
    margin-bottom: 17px;
  }

  &__to-back {
    display: flex;
    align-items: center;
    max-width: max-content;
    user-select: none;
    color: $color-interactive;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    transition: all 0.2s ease;
    cursor: pointer;

    .icon-left {
      margin-right: 5px;
      font-size: 20px;
    }

    &:hover {
      color: $color-button-hover;
    }

    &:active {
      color: $color-button-click;
    }
  }

  .loader-bg {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 15%;
  }

  &__container {
    display: flex;
  }

  &__settings {
    width: 100%;
    height: 85vh;
    margin-left: 20px;
    padding: 29px 32px 20px 32px;
    overflow: scroll;
    border-radius: 24px;
    background-color: $color-white;

    &--book {
      height: 80vh;

      @media (max-width: $md) {
        max-width: 100% !important;
      }
    }

    &--bookedit {
      max-width: 762px;
      height: fit-content;
    }

    &__title {
      margin-bottom: 20px;
      font-weight: 500;
      color: $color-text-accent;
      font-size: 16px;
      line-height: 20px;
    }

    /deep/ {
      .select-bg {
        margin-bottom: 20px;
        width: 100%;

        .select-content-bg {
          height: 34px;
        }

        .form-group__textarea {
          height: 160px;
        }
      }
    }

    &__files {
      display: flex;
      margin-bottom: 20px;

      .icon-paper-clip {
        margin-right: 11px;
        font-size: 20px;
        color: $color-text-secondary;
      }
    }

    &__links {
      display: flex;
      margin-bottom: 20px;

      .icon-chain {
        font-size: 20px;
        color: $color-text-secondary;
        margin-right: 11px;
        margin-top: -2px;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;

      @media (max-width: $sm) {
        flex-wrap: wrap;
        align-items: center;
        margin-top: 20px;
      }

      .button-widget {
        margin-right: 15px;

        @media (max-width: $sm) {
          width: 100%;
          margin: 0;
          order: 2;
        }

        /deep/ button {
          @media (max-width: $sm) {
            width: 100%;
          }
        }

        &:last-child {
          margin-right: 0;

          @media (max-width: $sm) {
            order: 1;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  /deep/ {
    .mark-select-bg {
      width: 100%;
      margin-bottom: 20px;

      .select-content-bg {
        height: 34px;

        .select-content {
          background: transparent;
        }
      }
    }

    .form-group__label {
      color: $text-disabled;
    }
  }

  .tabs {
    margin-bottom: 20px;
  }

  &__job-folder {
    display: flex;
    flex-direction: column;

    .label {
      display: flex;
      align-items: center;
      position: relative;

      .text {
        margin-right: 10px;
      }

      .icon-info {
        cursor: pointer;
        font-size: 12px;

        &:hover + .tooltip-reg {
          display: block;
        }
      }

      .tooltip-reg {
        display: none;
        position: absolute;
        top: -100px;
        left: 0;
        padding: 15px;
        z-index: 1000;

        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-white;

        background-color: $color-interactive;
        border-radius: 10px;
      }
    }
  }

  &__responsibilities {
    display: flex;
    flex-direction: column;

    .label {
      display: flex;
      align-items: center;
      position: relative;

      .text {
        margin-right: 10px;
      }

      .icon-info {
        cursor: pointer;
        font-size: 12px;

        &:hover + .tooltip-reg {
          display: block;
        }
      }

      .tooltip-reg {
        display: none;
        position: absolute;
        top: -80px;
        left: 0;
        padding: 15px;
        z-index: 1000;

        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-white;

        background-color: $color-interactive;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (max-width: $lg) {
  .knowledge-base-add-component {
    &__container {
      flex-flow: column-reverse;
      align-items: center;
    }
    &__settings {
      max-width: 540px;
      height: 100%;
      margin-left: 0;
      margin-bottom: 40px;
    }
  }
}
</style>
